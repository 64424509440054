import React, { useState } from 'react';

const LazyYouTubeEmbed = ({ videoId, title }) => {
    const [ isLoaded, setIsLoaded ] = useState(false);

    const loadVideo = () => {
        setIsLoaded(true);
    };

    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;

    return (
        <div className="youtube-embed-container"
             style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%' }}>
            {!isLoaded ? (
                <div onClick={loadVideo} style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                }}>
                    <img
                        src={thumbnailUrl}
                        alt={title}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '68px',
                        height: '48px',
                        backgroundColor: '#282828',
                        borderRadius: '14px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div style={{
                            width: 0,
                            height: 0,
                            borderStyle: 'solid',
                            borderWidth: '10px 0 10px 20px',
                            borderColor: 'transparent transparent transparent #ffffff'
                        }} />
                    </div>
                </div>
            ) : (
                <iframe
                    width="100%"
                    height="100%"
                    src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                    title={title}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                />
            )}
        </div>
    );
};

export default LazyYouTubeEmbed;
