import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useCountUp } from 'react-countup';
import * as classes from './AnimatedNumber.module.scss';

const AnimatedNumber = ({ id, targetNumber = 0, duration = 2, inline = true, className = '', suffix = '' }) => {
    const separator = 1234..toLocaleString().substring(1, 2);
    useCountUp({
        start: 0,
        end: targetNumber,
        duration,
        separator,
        enableScrollSpy: true,
        scrollSpyOnce: true,
        suffix,
        useEasing: false,
        ref: id
    });

    return <div className={clsx(inline && classes.Inline, className)}>
        <span id={id} />
    </div>;
};

AnimatedNumber.propTypes = {
    targetNumber: PropTypes.number.isRequired,
    duration: PropTypes.number,
    inline: PropTypes.bool,
    className: PropTypes.string,
    suffix: PropTypes.string,
    id: PropTypes.string.isRequired
};

export default AnimatedNumber;
